.summery{
padding: 2rem ;

& h3{
    font-weight: 100;
}

    &-medium{
      //  box-shadow: $third-shadow;
        
        &__price{
            margin-top: 2rem;

            &--total{
                margin-top: .5rem;
                & h3{
                    font-weight: bold !important;
                }
            }
        }

        &__submit{
            margin-top: 4rem;
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            box-shadow: $main-shadow;
            background-color: #fff;
            @include respond(s){
                display: inherit;
                position: relative;
                box-shadow: none;
            }
            &--total{

                @include respond(s){
                    display: none;
                }
                & h3{
                    text-align: center;
                }
            }
            &--button{
                padding: 15px 18px;
                @include respond(s){
                   padding: 0;
                }
                
            }
        }
    }
    &-small{

    }
}