
$breakpoint-alpha: 480px; // adjust to your needs

.rwd-table {
  margin: 1em 0;
  min-width: 100%; // adjust to your needs
  font-size: 1.6rem;
  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!   
  }
  
  td {
    display: block; 
    
    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
      font-weight: bold;

      // optional stuff to make it look nicer
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options
      
      @include respond(m) {
        display: none;
      }
    }
  }
  
  th, td {
    text-align: right;
    
    @include respond(m) {
      display: table-cell;
      padding: .25em .5em;
      
      &:first-child {
        padding-right: 0;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }

  }
  
  
}



.rwd-table {
  background: #34495E;
  color: #fff;
  border-radius: .4em;
  overflow: hidden;
  tr {
    border-color: lighten(#34495E, 10%);
  }
  th, td {
    margin: .5em 1em;
    @include respond(m) { 
      padding: 1em !important; 
    }
  }
  th, td:before {
    color: #dd5;
  }
}
