.cta-btn {

    &,
    &:link,
    &:visited {
        text-decoration: none;
        padding: .4rem 2rem;
        font-size: $default-font;
        display: inline-block;
        border-radius: .3rem;
        transition: all .2s;
        position: relative;
        border: none;

        font-family: inherit;
        //  box-shadow: $main-shadow;
        cursor: pointer;
        backface-visibility: hidden;
        margin: 0 1rem;
    }

    &:hover {
        box-shadow: $second-shadow;
        transform: translateY(-1.2px);

    }

    &:active,
    &:focus {
        outline: none;
        /*when we click*/
        transform: translateY(.5px);
        /*in relation to link not hover*/
        box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, .1);
    }

    &--small {
        font-size: 1.2rem;
    }
    &--large {
        font-size: 2rem;
        padding-right: 4rem;
        padding-left: 4rem;
    }

    &--light {

        background-color: transparent;
        border: 1px solid rgba($grey-light, .5);

        &-theme-events {
            color: $events-main;

            &:hover {
                border: 1px solid $events-main;

            }
        }

        &-theme-clinics {
            color: $clinics-main;

            &:hover {
                border: 1px solid $clinics-main;

            }
        }

        &-theme-hotels {
            color: $hotels-main;

            &:hover {
                border: 1px solid $hotels-main;

            }
        }

        &-theme-entertainment {
            color: $entertainments-main;

            &:hover {
                border: 1px solid $entertainments-main;

            }
        }
        &-theme-general {
            color: $general-main;

            &:hover {
                border: 1px solid $general-main;

            }
        }
    }
    &--time{
        padding: .4rem 1rem;
        background: transparent;
        margin-bottom: .4rem;
       &--active{
        &:hover {
            box-shadow: $card-shadow;
            transform: translateY(-1.2px);
            background-color: $clinics-main;
            color: #fff;

    
        }
       }
        &--disabled{
            text-decoration: line-through;
            &:hover {
                box-shadow: none;
             
                
    
        
            }
        }
    }
   

}