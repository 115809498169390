.dateWrapper{
margin-top: 2rem;
margin-bottom: 4rem;
}

.dateDisplay{
padding: 2rem 1rem .5rem 1rem;
position: relative;
border-top-left-radius: 11px!important;
border-bottom-right-radius: 11px!important;
box-shadow: $third-shadow;
 &__circle{
    position: absolute;
    background-color: #fff;
    color :$black-color;
    padding:1.3rem 1.5rem;
  font-size: 1.2rem;
    border-radius: 50%;
    top: -45%; 
    right: -1rem;
    border-width: 3px !important;
}
& p{
    color: white;
}
    &__from{
margin-left: 6rem;
position: relative;
&--after{
content: "5555";
display: inline-block ;
position: absolute;
left: -50%;
top: 54%;
bottom: 0;
    height: 3px;
    width: 6.5rem;
    background: black;
    z-index: 6;
}
    }

    &__to{

    }
}