.large-hero {
	position: relative;
	border-bottom: 1px solid #000;
//overflow: hidden;
	height: 70vh;
	
	&--small{
		height: 20vh;
	}
    &__overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: .3);
    }
	&__image {
        display: block;
        width: 100%;
        height: 100% ;
		/*for not having bottom margin*/
	}

	&__content {
	//	padding-top: 60px;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
        transform: translateY(-50%);
        
        
	
	}

	&__title {
		font-weight: bold;
		color: $white-color;
		font-size: 2.4rem;
		margin: 0;
        line-height: 1.6;
        @include respond(s) {
			font-size: 4rem;
		}


	}

	&__subtitle {
		font-weight: 300;
		color: $white-color;
		font-size: 1.5rem;
		margin-bottom: 2rem;

	
        @include respond(s) {
			font-size: 2rem;
		}

	}

	&__description {
		color: #fff;
		font-size: 1.1rem;
		font-weight: 100;
		text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
		max-width: 30rem;
		margin-right: auto;
		margin-left: auto;

		@mixin atSmall {
			font-size: 1.875rem;
		}
	}
}
