.detailRight {
    padding: 0;
    margin-top: 2rem;
    color: $color-text;

    &__price {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &--element {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            & h5 {
                margin-top: 1rem;
            }
           
        }
        &--full{
            color: tomato; 
        }
        &--orboon{
            color: lightgreen;
        }
    }
    &__location{
        display: flex;
        align-items: center;
       // justify-content: space-between;
        padding: 1rem;

        &--details{
            margin-right: 1rem;
            & p{
                font-size: 1.2rem;

                & a{
                    font-size: 1.2rem;
                    &:hover{
                        text-decoration: underline;

                    }
                }
            }


        }
    }
    &__form{
        & .error{
            background-color: tomato;
            color: #fff     ;
            padding: 1rem 2rem;
        }
    }
}

.detailBottom{
    box-shadow: $main-shadow;
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    bottom: 0;
    left: 0;
    padding: .2rem;
    background: #fff;
    align-items: center;
    text-align: center;
    @include respond(m){
        display: none;
    }
    & h6{
        font-size: $default-font;
    }
}
.detailLeft{

    &__title{
        margin-bottom: 5rem; 
        & span{
            font-size: 1.2rem;
        }
    }

    &__slider{
       margin-bottom: 5rem;
    }
    &__desc{

    }

    &__contentWrapper{
        & a{
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &__content{
        border-right: 3px solid $general-main;
        padding-right: 1rem;
    }
}
.productDetail{

    &--form{
        display: none;
        @include respond(m){
            display: none;
        }
    }
}