.like-wrapper {
  height: 80px;
  margin: 0 auto;
  position: relative;
  margin-right: 1rem;
}

.heart {
  cursor: pointer;
  padding: 10px 12px 8px;
  // background: $events-main;
  border-radius: 50%;  
  display: inline-block;
  margin: 0 0 15px;
  color: #fff;
  transition: .2s;
  animation: size2 .4s;

}

.heart:hover {
  color: rgba($color: #fff, $alpha: .7);
}

// .heart:before {
//   font-family: fontawesome;
//   content: '\f004';
//   font-style: normal;
// }

.like-text {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
 opacity: 0;
  text-align: center;
  transition: .3s;
  z-index: -2;
  font-size: 14px;

  color: #e23b3b;
  font-weight: 400;
 

}

.heart.press {
  animation: size .4s;
  color: #e23b3b;
}

.like-text.press {

 opacity: 1;
 
 
 
}

@keyframes fade {
  0% {
   
    opacity: 0;
  }

  100% {
 
    opacity: 1;
  }

 
}

@keyframes size {
  0% {
    padding: 10px 12px 8px;
  }

  50% {
    padding: 14px 16px 12px;
    margin-top: -4px;
  }

  100% {
    padding: 10px 12px 8px;
  }
}

@keyframes size2 {
  0% {
    padding: 10px 12px 8px;
  }

  50% {
    padding: 8px 10px 6px;
    margin-bottom: -4px;
  }

  100% {
    padding: 10px 12px 8px;
  }
}