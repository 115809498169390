.shop-options{
   
    & a{
        cursor: pointer;
    }
    &__icons{
        display: flex;
        align-items: center;
        justify-content: flex-end;

            & .m-left{
                margin-left: 2rem;
            }
        
        
        &--grid{
            & :first-child{
                margin-left: 1rem;
            }
        }
        & .icon{
           
        }
    }
}