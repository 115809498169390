.timeBox{

    &__button{
        
    }

    ul {
        width: 50%;
        margin-right: auto;
        margin-left: auto;
        list-style-type: none;
        background: white;
      }
      
      li {
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #e9ecef;
      }
      
      ul a {
        display: block;
        height: 100%;
        text-decoration: none;
        color: black;
        padding-right: 10px;
        position: relative;
        -webkit-transition: background .3s;
                transition: background .3s;
      }
      
      ul a:after {
        content: '⇢';
        position: absolute;
        left: 10px;
      }
      
      ul a:hover {
        background: #cdcbc4; 
      }
      
      
      
      /* CHECKBOX CONTAINER STYLES
      –––––––––––––––––––––––––––––––––––––––––––––––––– */
      
      .container { 
        position: relative; 
        height: auto;
        border-top: 0;
      }
      
      [type="checkbox"] {
        position: absolute;
        right:  -9999px;   
      }
      
      label {
        background: #e4e3df;
        display: block;
        width: 100%;
        height: 50px;
        cursor: pointer;
        position: relative;
        /*
         * no need to position absolutely the element 
         * because we are not interested in any transition effect
         * position: absolute;
         * top: 0;
         */
      }
      
      /*
       * use the rule below for testing purposes
       * label:hover {
       *    background: yellow;
       *  }
       */
      
      label:before,
      label:after {
          position: absolute;
      }
      
      label:before {
        content: 'More';
        right: 10px;
      }
      
      label:after {
        content: '⇣●';
        left: 10px;
        -webkit-animation: sudo .85s linear infinite alternate;
                animation: sudo .85s linear infinite alternate;
      }
      
      @keyframes sudo { 
          from { 
          -webkit-transform: translateY(-2px); 
                  transform: translateY(-2px); 
        }
          to { 
          -webkit-transform: translateY(2px); 
                  transform: translateY(2px); 
        }
      }
      
      input[type="checkbox"] ~ ul {
        width: 100%;
          display: none;
      }
      
      
      
      /* CHECKED STATE STYLES
      –––––––––––––––––––––––––––––––––––––––––––––––––– */
      
      [type="checkbox"]:checked ~ ul {
        display: block;
      } 
      
      [type="checkbox"]:checked + label {
        /**
         * if we have positioned relatively the element, 
         * during the "checked" state 
         * we have to change its type of positioning
         */
        position: absolute; 
        top: 100%;
      }
      
      [type="checkbox"]:checked + label:before {
        content: 'Less';
      }
      
      [type="checkbox"]:checked + label:after {
       content: '⇡●'; 
      }
      
      ul li:last-child {
        margin-bottom: 50px;
      }
      
      
      
      
}