

.messaeginBox.company {
	width: 330px;
	height: 100px;
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(-50%, -50%);
	border-radius: 5px;
	background-color: #fafafa;
	box-shadow: 0 0 2rem #babbbc;
	animation: show-profile .5s forwards ease-in-out;
}
.messaeginBox.general {
	width: 100px;
    height: 100px;
    position: fixed;
 bottom: 0;
	transform: translateY(-20px); // for animations
    right: 0;
	z-index: 2000;
	transition: all .3s; 
	animation: show-profile .5s forwards ease-in-out;
	&.active{
		// bottom: 120px;
	transform: translateY(-120px); // for animations

	}
}

@keyframes show-profile {
	0% {
		width: 0;
	}
}

.messaeginBox .photo, .messaeginBox .content {
	box-sizing: border-box;
}

.messaeginBox .photo {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid #fafafa;
	background-color: #fafafa;
	margin-right: -50px;
	box-shadow: 0 0 .5rem #babbbc;
	animation: rotate-photo .5s forwards ease-in-out;
}

@keyframes rotate-photo {
	100% {
		transform: rotate(-360deg);
	}
}

.messaeginBox .photo img {
	width: 100%;
}

.messaeginBox .content {
	padding: 10px;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
}

.messaeginBox .content::before {
	content: '';
	position: absolute;
	width: 230px;
	height: 150px;
	background-color: #e9f3e6;
	right: 0;
	top: -20px;
	z-index: -1;
	transform: rotate(-8deg);
}

.messaeginBox .content .text {
	margin-top: 20px;
	margin-right: 50px;
}

.messaeginBox .content .text h3, .messaeginBox .content .text h6 {
	font-weight: normal;
	margin: 3px 0;
	letter-spacing: .5px;
	white-space: nowrap;
}

.messaeginBox .btn {
	// background-color: #abc;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 25px;
	top: 25px;
	border-radius: 50%;
	z-index: 1;
	cursor: pointer;
	transition-duration: .3s;
	animation: pop-btn .3s both ease-in-out .5s;
}

@keyframes pop-btn {
	0% {
		transform: scale(0);
	}

	80% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

.messaeginBox .btn:hover {
	box-shadow: 0 0 0 5px rgba(170, 187, 204, 0.5);
}

.messaeginBox .btn span {
	width: 60%;
	height: 2px;
	position: absolute;
	background-color: white;
	top: 50%;
	right: 20%;
	transform: translateY(-50%);
	animation: to-hamburger .3s forwards ease-in-out;
}

.messaeginBox .btn span::before, .messaeginBox .btn span::after {
	content: '';
	width: 100%;
	height: 2px;
	position: absolute;
	background-color: white;
	transition-duration: .3s;
	transform: rotate(0deg);
	left: 0;
}

.messaeginBox .btn span::before {
	margin-top: -7px;
}

.messaeginBox .btn span::after {
	margin-top: 7px;
}

.messaeginBox .btn.active span {
	animation: to-arrow .3s forwards ease-in-out;
}

.messaeginBox .btn.active span::before, .messaeginBox .btn.active span::after {
	width: 60%;
	left: -1px;
}

.messaeginBox .btn.active span::before {
	transform: rotate(-45deg);
}

.messaeginBox .btn.active span::after {
	transform: rotate(45deg);
}

@keyframes to-hamburger {
	from {
		transform: translateY(-50%) rotate(-180deg);
	}
}

@keyframes to-arrow {
	from {
		transform: translateY(-50%) rotate(0deg);
	}

	to {
		transform: translateY(-50%) rotate(180deg);
	}
}

.messaeginBox .box {
	width: auto;
	height: 150px;
	opacity: 0;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.7);
	position: absolute;
	top: 50%;
	// left: -40%;
	transform: translate(50%, -50%);
	transition-duration: .3s;
}

.messaeginBox .box i {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #ececec;
	font-size: 26px;
	text-align: center;
	line-height: 50px;
	position: absolute;
	right: 18px;
	top: calc(75px - 50px/2);
	box-shadow: 0 0 .5rem #babbbc;
	transition-duration: .3s;
}

.messaeginBox .box i:hover {
	transition-delay: initial !important;
	box-shadow: 0 0 0 5px #babbbc;
}

.messaeginBox .box.open {
	opacity: 1;
}

.messaeginBox .box.open i {
	right: 60px;
}

.messaeginBox .box.open i:nth-of-type(1) {
	transform: rotate(-90deg) translateX(-120px) rotate(90deg);
	transition-delay: 0s;
}

.messaeginBox .box.open i:nth-of-type(2) {
	transform: rotate(-45deg) translateX(-120px) rotate(45deg);
	transition-delay: 0.1s;
}

.messaeginBox .box.open i:nth-of-type(3) {
	transform: rotate(0deg) translateX(-120px) rotate(0deg);
	transition-delay: 0.2s;
}

.messaeginBox .box.open i:nth-of-type(4) {
	transform: rotate(45deg) translateX(-120px) rotate(-45deg);
	transition-delay: 0.3s;
}

.messaeginBox .box.open i:nth-of-type(5) {
	transform: rotate(90deg) translateX(-120px) rotate(-90deg);
	transition-delay: 0.4s;
}