
.animateArrow{
    animation: animatArrow .9s infinite;
    position: relative;
  
}

@keyframes animatArrow{
    from {transform: translateX(-5px);}
    to {transform: translateX(-10px)}
}



  
  @keyframes zoomEffect {
    0% {
      -webkit-transform: scale(1) translateX(0) translateY(0);
              transform: scale(1) translateX(0) translateY(0);
    }
    100% {
      -webkit-transform: scale(1.2) translateX(20px) translateY(50px);
              transform: scale(1.2) translateX(20px) translateY(50px);
    }
  }
  