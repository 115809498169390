.booking-form {
    box-shadow: $second-shadow;
    // 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #fff;

    &-group {
        max-height: 87px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: $default-font;

        cursor: pointer;

        &.feild-destination {
            padding-left: 17px;
            transition: all 0.3s;
        }

        &__dropdown {
            margin-right: 2rem;

           

        }
    }
}