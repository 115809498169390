.checkboxContainer{
    display: flex ;
    vertical-align: middle;
cursor: pointer;
transition: all .3s;
    &__hidden{
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    &__styled{
      //  display: inline-block;
        width: 18px;
        height: 18px;
        background:  transparent;
        border-radius: 3px;
        transition: all 150ms;
        border: 2px solid $grey;
      cursor: pointer;
        .checkboxContainer__hidden:focus + & {
            &--checked{
                box-shadow: 0 0 0 5px rgba($color: $color-text, $alpha: .1 );

            }
        }
    
        

        &--icon{
            fill: none;
            stroke: white;
            stroke-width: 2px;
        }
    }

}