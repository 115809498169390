.header {

    &__container {
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--logo {
            height: 2rem;
        }

        &--ul {
            &__li {
                color: $black-color;
                &--link {
                    
                    
                    font-weight: 400;
                    vertical-align: middle;
                    border: none;
                    padding: 1rem 0; 
                }
            }
        }
    }
}