@mixin clearfix {
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

// 530 min Small
// 830 min Medium
// 1200 min Large
@mixin atSmall {
    @media (min-width: 530) {
        @content
    };
}
@mixin respond($breakpoint){
    @if $breakpoint == xs{
        @media (min-width: 25em) { //400
            @content
        };
    }
    @if $breakpoint == s{
        @media (min-width: 37.5em) { //600
            @content
        };
    }
    @if $breakpoint == m{
        @media (min-width: 57.5em) { //900
            @content
        };
    }
    @if $breakpoint == l{
        @media (min-width: 68.75em) { //1100
            @content
        };
    }
    @if $breakpoint == xl{
        @media (min-width: 112.5em) { //1800
            @content
        };
    }
    @if $breakpoint == h{
        @media (max-height: 26.875em) { //400
            @content
        };
    }

}

@mixin atMedium {
    @media (min-width: 830) {
        @content
    };
}
@mixin atLarge {
    @media (min-width: 1200) {
        @content
    };
}