$red: #000;
$main-color: #12d3cf;
$second-color:#67eaca;
$third-color: #b0f4e6;
$white-color:#fcf9ec;

$black-color: #2F2E41;
$grey: #7e7e7e;
$grey-light:#AEB0BF;
$color-text: #777;

$main-shadow:0 1rem 2rem rgba(0, 0, 0, .2);
$second-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$third-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
0px 2px 2px 0px rgba(0, 0, 0, 0.14),
0px 3px 1px -2px rgba(0, 0, 0, 0.12);

$card-shadow:0 0 1px 1px rgba(20,23,28,.1), 0 3px 1px 0 rgba(20,23,28,.1);
$default-font:1.6rem;



///////////////////
$events-main:#F7C355;
//////////////////////
$clinics-main:#3B92DF;


////////////////
$entertainments-main:#74D068;


///////
$hotels-main:#f67280;
///////
$general-main:#12d3cf;