.menu-dropdown {

    position: absolute;
    left: -60%;
    top: 170%;
    padding-top: .5rem;
    padding-left: 0;
    margin-bottom: 0;
    min-width: 200px;
    border-radius: .5rem;
    background: #fff;
    box-shadow: $third-shadow;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    font-size: 1.4rem;
    &__form {
        left: 0; 
        top: 120%;
        width: 100%;
        min-width: auto;

        &--date{
            right: 0;
            @include respond(m){
                width: 200%;
            }
        }
        
        &--dateSingle{
            right: 0;
            @include respond(m){
                width: 130%;
            }
        }
    }
    
    &__open {
        opacity: 1;
        visibility: visible;
        top: 150%;
        
        &--form {
            top: 100%;
        }
        
        &--doubleSize{
            
            @include respond(m){
                width: 200%;
            }
        }
    }

    &__item {
        padding: .7rem;
        cursor: pointer;

        &--icon {

            margin: 0 0 0 .5rem;
        }

        &:hover {
            box-shadow: $main-shadow;

            color: white;

        }

        &.-theme-events {
            &:hover {
                background-color: $events-main;

            }
        }

        &.-theme-entertainment {
            &:hover {
                background-color: $entertainments-main;

            }
        }

        &.-theme-hotels {
            &:hover {
                background-color: $hotels-main;

            }
        }

        &.-theme-clinics {
            &:hover {
                background-color: $clinics-main;

            }
        }
        &.-theme-general {
            &:hover {
                background-color: $general-main;

            }
        }

    }

    &__numItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
  
        &--wrapper {
            display: flex;
            & input {
                padding: 0;
                font-size: 16px;
                font-weight: 400;
                color: var(--link-color, #5191FA);
                text-align: center;
                border: none;
                box-shadow: none;
                width: 35px;
                margin-left: 10px;
                margin-right: 10px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
            }
        }
    }

}