.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.6rem;
  margin-left: -0.5rem;
  margin-top: -1.2rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 1.6rem;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.1s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 1.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -3.5rem;
  position: absolute;
  display: none;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -3.9rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.8rem;
  cursor: pointer;
  display: block;
  height: 0.8rem;
  position: relative;
  transition: left 0.1s ease-out, width 0.1s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.slider {
  margin: 0 auto;
  padding: 5rem 1rem;
  direction: ltr !important;
  touch-action: none;

  &__theme-events {
    & .input-range__slider {
      background: $events-main;
      border: 1px solid $events-main;

      &:focus {
        box-shadow: 0 0 0 5px rgba($events-main, 0.2);
      }
    }

    & .input-range__track--active {
      background: $events-main;
    }
  }

  &__theme-entertainment {
    & .input-range__slider {
      background: $entertainments-main;
      border: 1px solid $entertainments-main;

      &:focus {
        box-shadow: 0 0 0 5px rgba($entertainments-main, 0.2);
      }
    }

    & .input-range__track--active {
      background: $entertainments-main;
    }
  }

  &__theme-hotels {
    & .input-range__slider {
      background: $hotels-main;
      border: 1px solid $hotels-main;

      &:focus {
        box-shadow: 0 0 0 5px rgba($hotels-main, 0.2);
      }
    }

    & .input-range__track--active {
      background: $hotels-main;
    }
  }

  &__theme-clinics {
    & .input-range__slider {
      background: $clinics-main;
      border: 1px solid $clinics-main;

      &:focus {
        box-shadow: 0 0 0 5px rgba($clinics-main, 0.2);
      }
    }

    & .input-range__track--active {
      background: $clinics-main;
    }
  }

}

.input-range {
  /// margin-bottom: 160px;
}

.input-range__track.input-range__track--background span:nth-child(2) {

  & .input-range__label-container {
    bottom: -5rem;
  }

}

/*# sourceMappingURL=index.css.map */