*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 50%;

    /*deviding 16/default browser size! V.018*/
    // @include respond(s){
    //     font-size: 50%;
    // }
    @include respond(xs) {
        font-size: 55%;
    }

    @include respond(m) {
        font-size: 60%;
    }

    @include respond(l) {
        font-size: 66.5%;
    }

    @include respond(xl) {
        font-size: 75%;
    }
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Noto Kufi Arabic', sans-serif;
    direction: rtl;
    color: $black-color;
}
hr{
    background-color: $grey;
    margin-top:  1rem;

}
ol,
ul {
    list-style: none;
}

a {
    text-decoration: none ;
    color: inherit;
    font-size: 1.4rem;
    cursor: pointer;
}

p {
    font-weight: 300;
    color: $color-text;

    margin: 10px 0;
    font-size: 1.3rem;
    line-height: 1.4;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: .3);
    z-index: 5;
}