
.footer-social-icons {
    width: 350px;
    display:block;
    margin: 0 auto;
}
.social-icon {
    color: #fff;
}
ul.social-icons {
    margin-top: 10px;
   
}
.social-icons li {
    vertical-align: top;
    display: inline-block;
    // height: 100px; 
    margin-right: 1rem;
}
.social-icons a {
    color: #fff;
    text-decoration: none;
    position: relative;
}
.ic-facebook {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-facebook:hover {
    background-color: #3d5b99;
}
.ic-twitter {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-twitter:hover {
    background-color: #00aced;
}
.ic-whatsapp {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-whatsapp:hover {
    background-color: #44EA62;
}
.ic-youtube {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-youtube:hover {
    background-color: #e64a41;
}
.ic-linkedin {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-linkedin:hover {
    background-color: #0073a4;
}
.ic-github {
    padding:10px 20px; 
    border-radius: 10px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #322f30;
}
.ic-github:hover {
    background-color: #5a32a3;
}