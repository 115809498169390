.theme {


    &-events {
        background-color: $events-main;
        color: $white-color;
        &-color{
            color: $events-main;
        }
        &-border{
            border: 1px solid $events-main;
        }
    }

    &-hotels {
        background-color: $hotels-main;

        color: $white-color;
        &-color{
            color: $hotels-main;
        }
        &-border{
            border: 1px solid $hotels-main;
        }
    }

    &-clinics {
        background-color: $clinics-main;

        color: $white-color;
        &-color{
            color: $clinics-main;
        }
        &-border{
            border: 1px solid $clinics-main;
        }
    }


    &-entertainment {
        background-color: $entertainments-main;
        color: $white-color;
        &-color{
            color: $entertainments-main;
        }
        &-border{
            border: 1px solid $entertainments-main;
        }
    }
    &-general {
        background-color: $general-main;
        color: $white-color;
        &-color{
            color: $general-main;
        }
        &-border{
            border: 1px solid $general-main;
        }
    }

}
.animate{
    animation: dis .4s;
    position: relative;
    transition: all .3s;
}
.no-margin-left{
    margin-left: 0;
}
.no-margin{
    margin: 0;
}
.position-relative{
    position: relative;
}
.shadow-third{
    box-shadow: $third-shadow;
}
.border-none{
    border: none;
}
.errorMessage{
    padding: 18px;
    background-color: tomato;
    color: white;
}
.successMessage{
    padding: 18px;
    background-color: yellowgreen;
    color: white;
}
.link{
    transition: all .2s;

   &--hover{
    &:hover{
        text-decoration: underline;
    }
   }
    &.-theme-events{
        &.active{
            border-bottom:2px solid $events-main;
        }
        &:hover,&.active{
            color: $events-main;
        }
    }
   
    &.-theme-hotels{
        &:hover,&.active{
         
            color: $hotels-main;
        }
        &.active{
            border-bottom:2px solid $hotels-main;
        }
    }
    &.-theme-entertainment{
        &:hover,&.active{
            color: $entertainments-main;
        }
        &.active{
            border-bottom:2px solid $entertainments-main;
        }
    }
    &.-theme-clinics{
        &.active{
            border-bottom:2px solid $clinics-main;
        }
        &:hover,&.active{
            color: $clinics-main;
        }
    }
    &.-theme-general{
        &.active{
            border-bottom:2px solid $general-main;
        }
        &:hover,&.active{
            color: $general-main;
        }
    }
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: .3);
    overflow: hidden;

}
.position-middle{
    position: absolute;
    top: 50%;
  
right: 50%;
    transform: translate(50%,-50%);
    z-index: 5;
    width: 100%;
}
.overflow-hidden{
    overflow: hidden;
}
.img-hover{
    .hovered{
        transition: all .3s;
    }
&:hover .hovered{

        transform: scale(1.1);

    
}}
.hidden-sm{
    display: none;

    @include respond(m){
        display: inline-block;
    }
}

.icon{
    vertical-align: middle;
    transition: all .3s;
    height: 2rem;
    width: 2rem;
    &__inside{
        position: relative;
        top: 3px;
    }
   
}

.w-100{
    width: 100%;
}
.font-Default{
    font-size: $default-font;
}
.font-big{
    font-size: 3.5rem;
}
.line-heghit-1{
    line-height: 1;
}
.mg-bottom{
    margin-bottom: 1rem;
}
.mg-top{
    margin-top: 5rem;
}
.pd-1{
    padding: 1rem;
}
.mg-left{
    margin-left: 1rem;
}
.border-top{
    border-top: 1px solid rgba($color: $grey, $alpha: .2);
}
.border-bottom{
    border-bottom: 1px solid rgba($color: $grey, $alpha: .2);
}
.no-padding-left{
    padding-left: 0;
}

.no-padding-right{
    padding-right: 0;
}
.no-padding{
    padding: 0;
}
.paper{
    background: white;
    box-shadow: $main-shadow;
    padding: 1rem 2rem;
    border-radius: 1rem;
    margin: 1rem;
    @include respond(m){
        margin: 2rem;

    }
}
.papers{
    position: relative ;
    z-index: 5;
    top: -25px;
    @include respond(m){
        top: -50px;

    }
}
.customModal{

    @include respond(m){
        display: none;
    }
}

.no-shadow{
    box-shadow: none;
}
.upOverlay{
    position: relative;
    z-index: 6;
}
   

/////////override
*:focus{
    outline: none;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header{
    flex-direction:row-reverse !important;
    justify-content: space-between;
    background: #ffffff;
    font-size: $default-font;

}
.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 50vh !important;
}
.full-slider .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    // max-height: 100vh !important;
}
.image-gallery-content.fullscreen{
    top:50% !important;
    transform: translateY(-50%) !important;
}

.full-slider .image-gallery-slide-wrapper.left, .full-slider .image-gallery-slide-wrapper.right {
    
    width: 100% !important;
}