.profile{
    background-color: $general-main;
    max-width: 30rem;
    &__user-info{
        background-color: #fff;
        border-bottom-left-radius: 20%;
        border-bottom-right-radius: 20%;
        &--image{
            width: 10rem;
            height: 10rem;
        }  
        &--name{
            
        }
        &--username{
            
        }
    }

    &__nav{
        &--button{
            & h1{

            }
            & h6{

            }
        }
    }
    &__body{
       
        height: 6rem;
    }
}

.clearfix:after,
.clearfix:before {
  content: '';
  display: table
}

.clearfix:after {
  clear: both;
  display: block
}

.muck-up {
  //max-width:  810px;
   
    margin: 0em auto;
  position: relative;
  overflow: hidden;
}

.user-overlay { 
   // background: $general-main;
  //  background: url(https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/header.jpg) no-repeat top /contain;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.user-overlay:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(71, 32, 84, 0.5);
}

.muck-up > .top {
  position: relative;
  min-height: 240px;

  padding: 15px;
  color: #fff;
  @include respond(m){
    min-height: 260px;

  }
}

.top .nav span {
  float: right;
  display: block;
}

.nav p {
  margin-top: 2px;
  display: inline-block;
 // float: right;
  vertical-align: bottom;
}

.ion-android-menu {
  font-size: 24px;
  margin-left: 17px;
}

.nav .ion-ios-more-outline {
  font-size: 38px;
  float: left !important;
}

.user-profile {
  margin-top: 3rem;
 
}

.user-profile img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  
  margin-left: 24px;
}

.user-details p {
  font-size: 11px;
}



.user-details h4 {
    font-size: 18px;
  }
  
.user-details p{
    margin-top: 0;
  }

.filter-btn {
  position: absolute;
  z-index: 2;
  left: 0;
  width: 40px;
  height: 40px;
  transition: all 0.4s ease-in-out 0s;
}

.filter-btn span {
  width: 40px;
  height: 40px;
//   background: #FA396B;
  background: $general-main;
  display: block;
  position: absolute;
  left: 25px;
  top: -66px; 
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
  font-size: 22px;
  z-index: 999;
  @include respond(m){
    top: -68px; 
  }
}
span.toggle-btn.ion-android-funnel:hover{
      cursor: pointer; 
}
.filter-btn a {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  left: 25px;
  display: block;
  top: -66px;
  color: #fff;
  z-index: 99;
  font-size: 22px;
  transition: all .4s cubic-bezier(.68, 1, .265, 1);
  @include respond(m){
    top: -68px; 
  }
}

.filter-btn:after {
  height: 170px;
  width: 170px;
  content: '"\f38b"';
//   background-color: #FA396B;
  background-color: $general-main;
  position: absolute;
  top: -130px;
  left: -40px;
  border-radius: 50%; 
  transform: scale(0);
  transition: all 0.3s ease-in-out 0s;
  @include respond(m){
  top: -130px;
    
  }
}

.filter-btn.open span.toggle-btn.ion-android-funnel {
  background-color: rgba($color: #000000, $alpha: .2)
} 

// .filter-btn.open .ion-android-funnel:before {
//   content: "\f2d7";
// }

.open:after {
  transform: scale(1);
}

.filter-btn.open a:nth-child(1) {
  transform: translate(-9px, -62px);
}

.filter-btn.open a:nth-child(2) {
  transform: translate(50px, -34px);
}

.filter-btn.open a:nth-child(3) {
  transform: translate(56px, 25px);
}

.filter-btn.open a:nth-child(4) {
  transform: translate(5px, 61px);
}

.muck-up .bottom {
  background-color: #fff;
  min-height: 303px;
  z-index: 1;
  padding: 35px;
  position: relative;
  color: #222;
  padding-top: 0px;
}

.bottom:after {
    content: '';
    position: absolute;
    top: -63px;
    background: #fff;
    right: -16px;
    left: 0; 
    height: 100px;
    transform: rotate(-9deg);
    width: 105%;
    z-index: -1; 
    @include respond(m){
        top: -80px;
    
        height: 160px;
        transform: rotate(-3deg);
    }
}

.bottom .title {
  margin-bottom: 20px;
}

.bottom .title h3 {
  font-size: 22px;
  margin-bottom: 5px;
}

.title small {
  font-size: 10px;
  color: #888;
  text-transform: uppercase;
  letter-spacing: 1px;
}

ul.tasks .task-title {
  font-size: 15px;
  display: inline-block;
}

ul.tasks .task-time {
  float: left;
  font-size: 12px;

}

ul.tasks .task-cat {
  font-size: 13px;
  display: block;
  color: #888;
}

ul.tasks li {
  margin-bottom: 16px;
  position: relative;
  z-index: 8;
  padding-bottom: 1rem;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: .1);
}

ul.tasks li:after {
  content: '';
  position: absolute;
  right: -18px;
  top: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

ul.tasks li.events:after {
  background: $events-main;
  
}

ul.tasks li.clinics:after {
 
  background: $clinics-main;

}

ul.tasks li.entertainment:after {
  background: $entertainments-main;

}
ul.tasks li.hotels:after {
  background: $hotels-main;

}

ul.tasks::after {
  content: '';
  position: absolute;
  height:130%;
  width: 1px;
  background: #eee;
  right: -15px;
  top: -110px;
}

ul li.hang {
  margin-bottom: 48px;
}

ul li.hang img {
  float: right;
  height: 20ox;
  width: 20px;
  border-radius: 50%;
  margin-left: 8px;
}

