.navTabs{
 //   padding: 1rem;
   
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    
   // margin-bottom: 2rem;
    
   // background: rgb(247, 247, 247);
  
  
    &__ul{
        padding: 1rem 0;
          /* Make this scrollable when needed */ 
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* Make an auto-hiding scroller for the 3 people using a IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;
    /* Remove the default scrollbar for WebKit implementations */
 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &::-webkit-scrollbar {
            display: none;
        } 
        & li{
            
            & a{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding: .5rem 2rem;
                color: #5f7d95;
                text-decoration: none;
                -webkit-transition: opacity .3s;
                transition: opacity .3s;
                font-size: $default-font;
              
                
            }
        }
    }
}