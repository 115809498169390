.cardBlockShop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {

    margin-bottom: 20px;
    padding: 0;
    box-sizing: border-box;
    // position: relative;
    overflow: hidden;
    border-radius: .5rem;
    box-shadow: $card-shadow;
    background: rgba($color: $grey, $alpha: .03);

    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;

        &--link {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;

        }
    }

    &__clinicPeriod{
        background-color: #fff;
        border-radius: 10px;
      
        padding-right: 1rem;
        margin: 1rem auto;
        width: 100%;
        &>p{
            margin: 1rem 0;
            
        }
        &--date{
            background-color:$clinics-main;
            padding: .5rem 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            &>p{
                transform: rotate(-70deg);
                font-weight: bold;
                color: white;
            }
        }

    }

    &__img {
        width: 100%;
        height: 20rem;
        background-position: center !important;
        background-size: cover !important;
        border: 1px solid #f0f0f0;
    }

    &__body {
        flex: 1; //for taking the rest of the space
        color: $black-color;
        padding: 1.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--top {

            &--header {
                margin-bottom: .5rem;
                display: flex;
                align-items: center;

                & .icon {
                    width: 20px;
                    height: 20px;
                    margin-left: .5rem;
                    color: $color-text;
                }
            }

            &--title {
                font-size: $default-font;
            }

            &--sub {
                margin-top: .8rem;
                font-weight: 300;
            }

            &--company {

                font-size: 1rem;
                color: $black-color;

                & span {
                    margin-left: .5rem;
                    font-weight: 100;
                }

                & a {
                    // color: $events-main;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--bottom {
            justify-self: flex-end;
            margin-top: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: inherit;

            &--right {
                &__num {
                    margin-left: .3rem;
                    font-size: 18px;

                    font-weight: 500;
                }

                &__percentage {
                    position: absolute;
                    right: 0;
                    top: -15px;
                    //   text-decoration: line-through;
                    font-size: 1.4rem;
                    color: #cc0033 !important;
                    font-weight: 400;

                    & span {
                        font-size: 1rem;
                        margin-right: .4rem;
                    }
                }

                &__text {
                    font-size: 1rem;
                }
            }

            &--left {
                @include respond(m) {
                    visibility: hidden;
                    ;
                }

                @include respond(l) {
                    visibility: visible;
                    ;
                }

                &__num {
                    margin-right: .3rem;
                    font-size: 1.3rem;

                    font-weight: bold;
                    position: relative;
                }

                &__info {
                    position: absolute;
                    left: 0;
                    top: -15px;

                    font-size: 10px;
                    color: #cc0033 !important;
                    font-weight: 400;
                }

                &__price {}

                &__text {
                    font-size: 1rem;
                }
            }
        }
    }
}

.horizontal-card {
    border: 1px solid rgba(000, 000, 000, .2);

    & img {

        height: 240px;

        @include respond(m) {
            width: 150px;
            height: 200px;

        }
    }

    @include respond(m) {
        padding-left: 3rem;

    }

    margin-top:2rem;
    &__room-category {
        font-size: $default-font;
      
    }
    &__room-name {
        font-size: 1rem;
   //     margin: .6rem 0
    }

    &__room-price { 
        font-size: 1rem;

    }

    &__icon {
        text-align: center;
        margin-left: 1rem;

        & h5 {
            line-height: 1;
        }
    }
}