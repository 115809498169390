.general-form{
    max-width: 70rem;
    margin: 0 auto;
    border-top-left-radius: 11px!important;
    border-top-right-radius: 11px!important;
    border: 1px solid $general-main;
    box-shadow: $main-shadow;
    font-size: $default-font;
    background: #fff;
    &__body{
        padding: 3rem;
    
    }
    &__title{
       // margin-bottom: 4rem;
        background: $general-main;
        font-size: 1.15em;
        padding-top: 6px;
        padding-bottom: 6px;
        border-top-left-radius: 11px!important;
        border-top-right-radius: 11px!important;
        color: #fff;
       
        text-align: center;
    }
    &__footer{
        margin-top: 3rem;
        text-align: center;
        & hr{
            margin-bottom: 2rem;
        }
         & p{
             display: inline-block;
        
                 font-size: 1rem;
             
         }
    }
    }
    
    .form {
    
        &__block{
            margin-bottom: 2rem;
        
            
        }
        &__error{
            color: red;
           text-align: left;
        }
        &__group:first-child {
         //   margin-bottom: 2rem;
            margin-top: 3rem;
        // width: 90%;
         //   position: relative;
        }
      
        &__input {
            
            padding: .5rem 1rem;
            font-family: inherit;
            position: relative;
            color: inherit;
            border-radius: 5px;
           background-color: rgba($color: $grey, $alpha: 0.2);
            border: none;
            border-bottom: 3px solid transparent;
            width: 100%;
            display: block;
            transition: all .3s;
            &--small{
                width:100%;
                display: inline-block;
    
            }
            &:focus {
                outline: none;
                box-shadow: 0 1rem 2rem rgba($color: $black-color, $alpha: .1);
                border-bottom: 3px solid  $main-color;
                // & ~ label{
                //     transform: translate(-12%, -50%) scale(0.75);
                //     color: $main-color;
                // }
                
                // &:invalid {
                //     border-bottom: 3px solid $main-color;
                // }
            }
         
    
            &::-webkit-input-placeholder {
                color: #999;
            }
            &__error{
                border-bottom: 3px solid  red;
            }
            &__success{
                border-bottom: 3px solid  green;
            }
            // &__normal{
            //     border-bottom: 3px solid  indigo;
            // }
        }
    
    
        &__label {
            //  position: absolute;
             // top: 0;
      
              
              font-weight: 700;
           //   margin-right: 1rem;
              margin-top: .5rem;
              display: block;
              transition: all .3s; 
            //  line-height: 60px; 
              transform: translateY(-7.5rem);
            
          }
    
        &__input:placeholder-shown+div>&__label {
            // when the place holder is shown on the input, do the following
            //the + for choosing sibilings and ~ for all sibilings
            opacity: 0;
            visibility: hidden;
      transform: translateY(-4rem);
          
        }
      
        &__radio-group{
            width: 100%;
            margin-block-end: 2rem;
            display: inline-block;
            @include respond(s){ 
                width: 45%;
            }
    
        }
        &__radio-input{
            display: none;
        }
        &__radio-label{
            font-size: $default-font;
            cursor: pointer;
             position: relative;
             padding-right: 4rem;
         }
    
         &__radio-button{
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            border: 3px solid $main-color;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
    
            &::after{
                content: "";
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                background-color: $main-color;
                opacity: 0;
                transition: opacity .3s;
            }
    
         }
         &__radio-input:checked ~ &__radio-label &__radio-button::after{
            opacity: 1;
         }
    } 